<template>
    <div
        class="component component__image"
        :style="{ height: lineHeight }"
    >
        <img
            :src="require(`@/assets/images/${fileName}`)"
            :alt="alt"
            :style="{ width: width, height: height }"
        >
    </div>
</template>

<script>
export default {
  name: "ComponentImage",
  props: {
    alt: {
      type: String
    },
    fileName: {
      type: String
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '70px'
    },
    lineHeight: {
      type: String,
      default: '70px'
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentimage";
</style>
