<template>
    <div class="section section__techstack">
        <div class="container">
            <div class="columns is-multiline">
                <div class="column">
                    <div class="section__techstack_items">
                        <div class="columns">
                            <div class="column is-12-touch is-6-tablet is-3-desktop has-text-centered">
                                <div class="section__techstack_items_column">
                                    <ComponentParagraph :size="'medium'" :weight="'heavy'">Frontend</ComponentParagraph>
                                    <ComponentImage :height="'auto'" :width="'60px'" :line-height="'70px'" :alt="'vue.js'" :file-name="'vue.png'"></ComponentImage>
                                    <ComponentParagraph :margin="'none'">Vue.js</ComponentParagraph>
                                </div>
                            </div>
                            <div class="column is-12-touch is-6-tablet is-3-desktop has-text-centered">
                                <div class="section__techstack_items_column">
                                    <ComponentParagraph :size="'medium'" :weight="'heavy'">Backend</ComponentParagraph>
                                    <ComponentImage :alt="'php / symfony'" :file-name="'php.png'"></ComponentImage>
                                    <ComponentParagraph :margin="'none'">PHP / Symfony</ComponentParagraph>
                                </div>
                            </div>
                            <div class="column is-12-touch is-6-tablet is-3-desktop has-text-centered">
                                <div class="section__techstack_items_column">
                                    <ComponentParagraph :size="'medium'" :weight="'heavy'">Infrastructure</ComponentParagraph>
                                    <ComponentImage :alt="'aws'" :file-name="'aws.png'"></ComponentImage>
                                    <ComponentParagraph :margin="'none'">AWS Cloud</ComponentParagraph>
                                </div>
                            </div>
                            <div class="column is-12-touch is-6-tablet is-3-desktop has-text-centered">
                                <div class="section__techstack_items_column">
                                    <ComponentParagraph :size="'medium'" :weight="'heavy'">Test automation</ComponentParagraph>
                                    <ComponentImage :height="'auto'" :width="'110px'" :line-height="'70px'" :alt="'cypress'" :file-name="'cypress.png'"></ComponentImage>
                                    <ComponentParagraph :margin="'none'">Cypress.js</ComponentParagraph>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import ComponentImage from "@/components/shared/ComponentImage";

export default {
  name: "SectionTechStack",
  components: { ComponentImage, ComponentParagraph }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectiontechstack";
</style>
