<template>
    <div class="component component__button">
        <component
            ref="button"
            :class="classOutput"
            :is="isOutput"
            :href="to || anchor || undefined"
            :type="type"
            @click="$emit('click')"
        >
            <slot></slot>
        </component>
    </div>
</template>

<script>
export default {
  name: "ComponentButton",
  props: {
    to: {
      type: String
    },
    anchor: {
      type: String
    },
    type: {
      type: String,
      default: 'button'
    },
    rawText: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary'
      // allowed ['primary', 'secondary']
    },
    size: {
      type: String,
      default: 'normal',
      // allowed ['tiny', 'normal', 'full']
    },
    width: {
      type: String,
      default: 'normal',
      // allowed ['normal', 'full']
    },
    bold: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    reversed: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    margin: {
      type: String,
      default: 'none'
    }
  },
  computed: {
    classOutput () {
      return [
        'btn',
        this.theme ? `btn--${this.theme}` : 'btn--default',
        this.size ? `btn--size-${this.size}` : 'btn--size-normal',
        this.width ? `btn--width-${this.width}` : 'btn--width-normal',
        this.rawText ? `btn--raw-text` : '',
        this.outline ? `btn--outline` : '',
        this.bold ? `btn--bold` : '',
        this.reversed ? `btn--reversed` : '',
        this.transparent ? `btn--transparent` : '',
        this.margin ? `btn--margin-${this.margin}` : ''
      ]
    },
    isOutput () {
      let isStr
      
      if (this.to) {
        isStr = 'router-link'
      } else if (this.anchor) {
        isStr = 'a'
      } else {
        isStr = 'button'
      }
      
      return isStr
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentbutton";
</style>
