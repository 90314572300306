<template>
    <div class="section__hero_image">
        <PlaneSvg :class="'section__hero_image_plane'"></PlaneSvg>
        <CircleDarkBlueSvg :class="'section__hero_image_circle'"></CircleDarkBlueSvg>
    </div>
</template>

<script>
import PlaneSvg from '@/assets/images/plane.svg'
import CircleDarkBlueSvg from '@/assets/images/circle-dark-blue.svg'

export default {
  name: "SectionHeroImage",
  components: {
    PlaneSvg,
    CircleDarkBlueSvg
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionheroimage";
</style>