<template>
    <div class="section__contactus_image">
        <CircleDarkBlueSvg :class="'section__contactus_image_circle'"></CircleDarkBlueSvg>
    </div>
</template>

<script>
import CircleDarkBlueSvg from '@/assets/images/circle-dark-blue.svg'

export default {
  name: "SectionContactUsImage",
  components: {
    CircleDarkBlueSvg
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectioncontactusimage";
</style>