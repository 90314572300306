<template>
  <div id="app">
    <TheHeader></TheHeader>
    <TheMain></TheMain>
  </div>
</template>

<style lang="scss">

</style>
<script>
import TheHeader from "./layout/TheHeader";
import TheMain from "./layout/TheMain";

export default {
  components: { TheMain, TheHeader }
}
</script>
