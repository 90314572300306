<template>
    <div class="section section__aboutus">
        <ComponentAnchor :anchor="'about-us'"></ComponentAnchor>
        <SectionAboutUsImage></SectionAboutUsImage>
        <div class="container">
            <div class="columns">
                <div class="column is-6-desktop is-12-touch">
                    <ComponentTitle
                        :tag="'h2'"
                        :theme="'primary-dark'"
                        :margin-top="'none'"
                        :margin-bottom="'large'"
                    >About <strong>us</strong>
                    </ComponentTitle>
                    <ComponentParagraph
                        :theme="'primary-dark'"
                    >We are here just because we really like software craftsmanship. Before we came here, we have been working for multiple various companies. From big corporates to small start-ups. And from strict and formal projects
                        planned for years to small, swift, and agile inventions. Through years we managed to forge our experience into a vision of not only what we like to do but also how and with what kind of people.
                    </ComponentParagraph>
                    <ComponentParagraph
                        :theme="'primary-dark'"
                    >And then we started a new journey. Journey where we combine technical skills with open communication to create truly creative environment. So that we can deliver a true value to our customers and build a bond of mutual
                        understanding.
                    </ComponentParagraph>
                    <ComponentParagraph
                        :theme="'primary-dark'"
                    >We know that we are good in what we are doing and that together we are getting even better. Thanks to people we work with and work for.
                    </ComponentParagraph>
                    <ComponentParagraph
                        :theme="'primary-dark'"
                        :align="'right'"
                        :margin="'none'"
                    ><i>DG Software team</i>
                    </ComponentParagraph>
                </div>
                <div class="column is-6">
                    <SectionAboutUsImageMan></SectionAboutUsImageMan>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentTitle from "@/components/shared/ComponentTitle";
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import SectionAboutUsImage from "@/sections/SectionAboutUsImage";
import SectionAboutUsImageMan from "@/sections/SectionAboutUsImageMan";
import ComponentAnchor from "@/components/shared/ComponentAnchor";

export default {
  name: "SectionAboutUs",
  components: { ComponentAnchor, SectionAboutUsImageMan, SectionAboutUsImage, ComponentParagraph, ComponentTitle }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionaboutus";
</style>
