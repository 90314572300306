<template>
    <div class="section section__ourservices_menucontent">
        <div class="columns">
            <div class="column is-12-touch is-3-desktop">
                <div class="section__ourservices_menucontent_menu">
                    <ul>
                        <li>
                            <ComponentButton
                                :raw-text="true"
                                :theme="activeKey === 'business-analysis' ? 'secondary' : 'primary'"
                                :reversed="activeKey !== 'business-analysis'"
                                :margin="'small'"
                                @click="setActiveKey('business-analysis')"
                            >Business analysis</ComponentButton>
                        </li>
                        <li>
                            <ComponentButton
                                :raw-text="true"
                                :theme="activeKey === 'solution-architecture' ? 'secondary' : 'primary'"
                                :reversed="activeKey !== 'solution-architecture'"
                                :margin="'small'"
                                @click="setActiveKey('solution-architecture')"
                            >Solution architecture</ComponentButton>
                        </li>
                        <li>
                            <ComponentButton
                                :raw-text="true"
                                :theme="activeKey === 'code-development' ? 'secondary' : 'primary'"
                                :reversed="activeKey !== 'code-development'"
                                :margin="'small'"
                                @click="setActiveKey('code-development')"
                            >Code development</ComponentButton>
                        </li>
                        <li>
                            <ComponentButton
                                :raw-text="true"
                                :theme="activeKey === 'quality-assurance' ? 'secondary' : 'primary'"
                                :reversed="activeKey !== 'quality-assurance'"
                                :margin="'small'"
                                @click="setActiveKey('quality-assurance')"
                            >Quality assurance</ComponentButton>
                        </li>
                        <li>
                            <ComponentButton
                                :raw-text="true"
                                :theme="activeKey === 'team-building-and-management' ? 'secondary' : 'primary'"
                                :reversed="activeKey !== 'team-building-and-management'"
                                :margin="'small'"
                                @click="setActiveKey('team-building-and-management')"
                            >Team building and management</ComponentButton>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column is-12-touch is-9-desktop">
                <div class="section__ourservices_menucontent_content">
                    <article v-if="activeKey === 'business-analysis'">
                        <ComponentParagraph>Our customers understand their business best. We just know how to build IT services that help those businesses to grow.</ComponentParagraph>
                        <ComponentParagraph>So that is the reason we keep asking why until we know what suits particular cases.</ComponentParagraph>
                        <ComponentParagraph>It helps us to build backlog that allows to:</ComponentParagraph>
                        <ComponentTextList :items="[
                            'measure progress,',
                            'cut out things that does not meet timeframes or budget,',
                            'save time during implementation and testing.'
                        ]"></ComponentTextList>
                        <ComponentParagraph>But business analysis does not end here. We keep an eye on every project phase so that we preserve flexibility to react to new opportunities, threads and lessons learned.</ComponentParagraph>
                    </article>
                    <article v-if="activeKey === 'solution-architecture'">
                        <ComponentParagraph>Our understanding of what needs to be done would not be complete without planning and proofing ways how to achieve it.</ComponentParagraph>
                        <ComponentParagraph>Mindful technical preparation and planning must prevent us from underestimating upcoming infrastructure and performance needs as well as overspent time and budget by using a sledgehammer to crack a nut.</ComponentParagraph>
                        <ComponentParagraph>And as well as business analysis it is not something we do once. Plan – implement – test – improve – it is our creed.</ComponentParagraph>
                    </article>
                    <article v-if="activeKey === 'code-development'">
                        <ComponentParagraph>We do deliver code. This is the most obvious thing one can say about us.</ComponentParagraph>
                        <ComponentParagraph>But what is more important is how we do it. And to be honest we are good in it.</ComponentParagraph>
                        <ComponentParagraph>How can we say it? Here are some facts:</ComponentParagraph>
                        <ComponentTextList :items="[
                            `<h4>We are effective because we do prepare.</h4>That is the reason why we do all the business analysis and solution architecture so that we do not waste time and effort on things that do not match our customer’s needs.`,
                            `<h4>We are experienced. Yet still we learn more with every sprint.</h4>Most of us has more than 10 years of practical experience and one of things that keeps us doing our stuff is that we really like to learn new things.`,
                            `<h4>We do care about security.</h4>Being ISO 27001 compliant is nothing strange to us. But we never trust ourselves too much still. That is why we like to get our code tested externally from time to time.`,
                            `<h4>Cloud is nice. Especially AWS.</h4>We do work with it for years now. And our creations perform for thousands of users. And again, we listen to experts and continuously learn here too.`
                        ]"></ComponentTextList>
                    </article>
                    <article v-if="activeKey === 'quality-assurance'">
                        <ComponentParagraph>Mature software craftsmanship, like other activities, requires responsibility and self-awareness. A part of them is realisation on how many levels mistakes can be made.</ComponentParagraph>
                        <ComponentParagraph>Analysis and specification may miss some specific cases. Implementation can somehow skip a bit of acceptance criteria, misunderstand it or can contain a type that will not be detected by automated code review. Even a feature free from all of those possible issues can still misbehave when is integrated with bigger part or its performance under stress can be not as good as predicted. All of this happen from time to time.</ComponentParagraph>
                        <ComponentParagraph>Truth is that all we can do is to detect such things before they get to live environment. And we have a lot of tools to do so. Our QA specialists:</ComponentParagraph>
                        <ComponentTextList :items="[
                            'review backlog items,',
                            'design and document test cases and test runs,',
                            'do functional, integration and performance testing,',
                            'use manual and automated tests.'
                        ]"
                        ></ComponentTextList>
                    </article>
                    <article v-if="activeKey === 'team-building-and-management'">
                        <ComponentParagraph>We do believe that our projects have as many chances of success as strong our teams are. That is a reason why from earliest stages we keep asking ourselves who will be needed in given project not only from technical skills point of view.</ComponentParagraph>
                        <ComponentParagraph>What we always aim at is a team built of people who truly engage into work they do, communicate openly, and feels good together. Thanks to that we can perform better.</ComponentParagraph>
                        <ComponentParagraph>And there is one more secret in here: There is no we and they when we think about our customers. It is we.</ComponentParagraph>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import ComponentTextList from "@/components/shared/ComponentTextList";
import ComponentButton from "@/components/shared/ComponentButton";

export default {
  name: "SectionOurServicesMenuContent",
  components: { ComponentButton, ComponentTextList, ComponentParagraph },
  data () {
    return {
      activeKey: 'business-analysis'
    }
  },
  methods: {
    setActiveKey (value) {
      this.activeKey = value
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/_sectionourservicesmenucontent.scss";
</style>
