<template>
    <div class="view view__home">
        <SectionHero></SectionHero>
        <SectionAboutUs></SectionAboutUs>
        <SectionOurServices></SectionOurServices>
        <SectionTechStack></SectionTechStack>
        <SectionCaseStudies></SectionCaseStudies>
        <SectionContactUs></SectionContactUs>
    </div>
</template>

<script>
import SectionHero from "../sections/SectionHero";
import SectionContactUs from "../sections/SectionContactUs";
import SectionAboutUs from "@/sections/SectionAboutUs";
import SectionOurServices from "@/sections/SectionOurServices";
import SectionTechStack from "@/sections/SectionTechStack";
import SectionCaseStudies from "@/sections/SectionCaseStudies";
export default {
  name: "ViewHome",
  components: { SectionCaseStudies, SectionTechStack, SectionOurServices, SectionAboutUs, SectionContactUs, SectionHero }
}
</script>

<style scoped>

</style>
