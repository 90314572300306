<template>
    <div class="section section__casestudies_stories">
        <div class="columns">
            <div class="column is-12-touch is-6-desktop">
                <ComponentBox>
                    <ComponentTitle :tag="'h3'" :margin-bottom="'normal'">Digital Workbook for Form LMS project</ComponentTitle>
                    <ComponentParagraph>One of our customer’s prospects revealed that a new feature is needed: kind of digital workbook where learners can write down notes proofing progress they make upon their training process. Important
                        part of such feature was possibility to communicate with trainer in form of simply chat next to each of workbook items.
                    </ComponentParagraph>
                    <ComponentButton @click="openCaseStudyModal('casestudydigitalworkbook')">Read more</ComponentButton>
                    <ComponentModal :title="'Case study #1'" ref="casestudydigitalworkbook">
                        <ComponentTitle :tag="'h2'" :margin-bottom="'large'" :margin-top="'none'">Starting with value to build mutual trust and understanding: <br><strong>MVP for Form LMS project</strong></ComponentTitle>
                        <ComponentTitle :tag="'h3'" :margin-bottom="'medium'" :margin-top="'very-large'">Background</ComponentTitle>
                        <ComponentParagraph>By the time we met Me Learning they were organisation with many years of experience in digital training delivery. What makes them valuable partner to their customers is ability not only to create learning content but also to provide feature rich platform to serve it to learners. Truth is that as time passed it needed not only addition of new features but also solid revision of existing functionality.</ComponentParagraph>
                        <ComponentParagraph>After a few attempts to enrich what was in use by the time, decision was made that it is time to rewrite whole platform to open it for future improvements.</ComponentParagraph>
                        <ComponentParagraph>A factor that was particularly visible to us was that after a few not successful attempts to move forward Me Learning’s stakeholders were impatient to see positive results. But building feature rich Learning Management System from a scratch is not something that can be done even in just a few sprints. Especially within modest budget.</ComponentParagraph>
                        <ComponentParagraph>In such circumstances we knew that what we must look for is Minimum Viable Product that will be on one had just a subset of LMS features but on the other for Me Learning can be king of game changer.</ComponentParagraph>
    
                        <ComponentTitle :tag="'h3'" :margin-bottom="'medium'" :margin-top="'very-large'">First steps: Looking for hot spot</ComponentTitle>
                        <ComponentParagraph>Knowing the background made us aware of simple fact that since the very beginning of Form LMS project we must ask not only what and why we need to deliver but also what is biggest problem now for Me Learning and their customers.</ComponentParagraph>
                        <ComponentParagraph>Pretty soon we realised together that there is one particular risk for Me Learning businesses – classroom training management module for their existing LMS platform that was supposed to be pretty important selling point brought a lot of problems at its arrival. Quick round of additional questions revealed that this is it – something that we can deliver quick, especially when we compare it to whole LMS platform, and what makes a solid difference.</ComponentParagraph>
    
                        <ComponentTitle :tag="'h3'" :margin-bottom="'medium'" :margin-top="'very-large'">Cutting edges to keep it simple</ComponentTitle>
                        <ComponentParagraph>Classroom booking subsystem seems to be simple. What can it hold beside learner, session and booking status?</ComponentParagraph>
                        <ComponentParagraph>Learners were easiest to manage – we just integrated existing LMS with our new solution in form of simplest SSO to check who to let in. Of course, we had to handle users at our end somehow, but fortunately AWS Cognito helps a lot here.</ComponentParagraph>
                        <ComponentParagraph>Session brought more complexity. After all it requires some classroom that exists in a venue that has own location. In here Google Maps brought handy solution for address adding and location display. But it is not an end to session’s definition – it also requires a trainer to lead it. And what if my session takes three days and on each of them it takes place in different room and is led by different trainers?</ComponentParagraph>
                        <ComponentParagraph>Then we have booking status and its lifecycle that must cover possibility to cancel but not too late. What is more a trainer must get attendance list to mark who really participated. And even if for some training programs participation is enough to pass in some other trainers have to decide who passed and who not. It is not an end of functionalities related to booking status – changes of it are so important that right people must be notified by appropriate email notifications.</ComponentParagraph>
                        <ComponentParagraph>And this is our must haves list. Had we more? Yes, but all beside this was cut from initial scope to keep it simplest but still valuable from the business perspective.</ComponentParagraph>
    
                        <ComponentTitle :tag="'h3'" :margin-bottom="'medium'" :margin-top="'very-large'">Proof of concept</ComponentTitle>
                        <ComponentParagraph>After initial days when we built mutual understanding of what new LMS must be and which part of it we may consider to be MVP, we found ourselves in good position to start actual development. Still our estimates of MVP were weeks long and Me Learning had to see we can not only define scope but also deliver.</ComponentParagraph>
                        <ComponentParagraph>It is hard to ask someone to wait patiently for first visible result entire weeks. That is why we once again took a look at our scope and decided to cut out first bit that can be a proof of our skills. What we found was possibility to define venues and rooms – with addresses defined manually or using Google Maps search and with possibility to illustrate newly created objects with images.</ComponentParagraph>
                        <ComponentParagraph>Delivering them first made Me Learning much more confident we can bring entire classroom training management subsystem as they want it in reasonable time.</ComponentParagraph>
    
                        <ComponentTitle :tag="'h3'" :margin-bottom="'medium'" :margin-top="'very-large'">Lessons learned</ComponentTitle>
                        <ComponentParagraph>Mutual trust comes with time. Sometimes it is even harder to build it up with customers who had not good experiences with IT projects before. But it is essential to get it as soon as it is possible because without trust it is hard for open communication. Without it everything gets complicated, and chances of project failure grows rapidly.</ComponentParagraph>
                        <ComponentParagraph>Could we deliver Form LMS without starting from classroom training module? Technically, yes. But from point of view of our relationship with Me Learning as our customer it was essential. It showed two important things:</ComponentParagraph>
                        <ComponentTextList :items="[
                            '1. We could deliver product they were willing to get.',
                            '2. Even things that at first glance seems to be simple often turns out to be solid piece of work to be done. Especially when we have to built product rich in various edge case to suit needs of different end users.'
                        ]"></ComponentTextList>
                        <ComponentParagraph>Having those two factors exposed made it a lot easier to step into three years long way that led us through Form LMS delivery.</ComponentParagraph>
                    </ComponentModal>
                </ComponentBox>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentBox from "@/components/shared/ComponentBox";
import ComponentTitle from "@/components/shared/ComponentTitle";
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import ComponentButton from "@/components/shared/ComponentButton";
import ComponentModal from "@/components/shared/ComponentModal";
import ComponentTextList from "@/components/shared/ComponentTextList";

export default {
  name: "SectionCaseStudiesStories",
  components: { ComponentTextList, ComponentModal, ComponentButton, ComponentParagraph, ComponentTitle, ComponentBox },
  methods: {
    openCaseStudyModal (modalRef) {
      this.$refs[modalRef].openModal()
    }
  }
}
</script>

<style scoped>

</style>
