<template>
    <div class="header__contact">
        <div class="header__contact_action">
            <ComponentButton
                :anchor="'#contact-us'"
                :outline="true"
            >Contact us
            </ComponentButton>
        </div>
    </div>
</template>

<script>
import ComponentButton from "@/components/shared/ComponentButton";

export default {
  name: "TheHeaderContact",
  components: { ComponentButton }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/layout/header/headercontact";
</style>