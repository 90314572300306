<template>
    <main class="main">
        <router-view></router-view>
    </main>
</template>

<script>
export default {
  name: "TheMain"
}
</script>

<style scoped>

</style>