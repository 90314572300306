<template>
    <div class="section section__contactus">
        <ComponentAnchor :anchor="'contact-us'"></ComponentAnchor>
        <SectionContactUsImage></SectionContactUsImage>
        <div class="container">
            <div class="columns">
                <div class="column is-5">
                    <ComponentTitle
                        :tag="'h2'"
                        :theme="'white'"
                        :margin-top="'none'"
                        :margin-bottom="'small'"
                    >Contact <strong>us</strong></ComponentTitle>
                    <ComponentParagraph
                        :theme="'white'"
                        :size="'medium'"
                        :margin="'large'"
                    >DG Software Dominik Góral, Jędrzej Danko S.C.
                    </ComponentParagraph>
                    <ComponentParagraph
                        :size="'small'"
                        :theme="'white'"
                        :margin="'none'"
                    >Tax ID: PL 899 289 90 24
                    </ComponentParagraph>
                    <ComponentParagraph
                        :size="'small'"
                        :theme="'white'"
                    >Address: 53-024 Wrocław, ul. Wietrzna 109a / 6
                    </ComponentParagraph>
                    <ComponentParagraph
                        :size="'small'"
                        :theme="'white'"
                    >eMail: <a href="mailto:biuro@dgsoftware.pl">biuro@dgsoftware.pl</a>
                    </ComponentParagraph>
                    <ComponentParagraph
                        :size="'small'"
                        :theme="'white'"
                    >{{ year }} © {{ siteUrl }} | All rights reserved
                    </ComponentParagraph>
                </div>
                <div class="column">
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentTitle from "@/components/shared/ComponentTitle";
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import SectionContactUsImage from "@/sections/SectionContactUsImage";
import ComponentAnchor from "@/components/shared/ComponentAnchor";

export default {
  name: "SectionContactUs",
  components: { ComponentAnchor, SectionContactUsImage, ComponentParagraph, ComponentTitle },
  computed: {
    year () {
      return new Date().getFullYear()
    },
    siteUrl () {
      return window.location.host
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectioncontactus";
</style>
