<template>
    <div class="section section__hero">
        <ComponentAnchor :anchor="'home'"></ComponentAnchor>
        <div class="container is-fullheight">
            <div class="columns is-multiline is-vcentered is-fullheight">
                <div class="column has-text-centered-touch is-12-touch is-narrow-desktop">
                    <ComponentTitle
                        :theme="'white'"
                        :margin-bottom="'small'"
                    >IT <span>solutions</span></ComponentTitle>
                    <ComponentParagraph
                        :theme="'white'"
                        :size="'large'"
                    >build in open communication environment.</ComponentParagraph>
                    <ComponentParagraph
                        :theme="'primary-light'"
                        :size="'medium'"
                    >
                        This is software craftsmanship we like to deliver.
                    </ComponentParagraph>
                </div>
                <div class="column is-12-touch is-align-self-top is-relative">
                    <SectionHeroImage></SectionHeroImage>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentTitle from "@/components/shared/ComponentTitle";
import ComponentParagraph from "@/components/shared/ComponentParagraph";
import SectionHeroImage from "@/sections/SectionHeroImage";
import ComponentAnchor from "@/components/shared/ComponentAnchor";

export default {
  name: "SectionHero",
  components: { ComponentAnchor, SectionHeroImage, ComponentParagraph, ComponentTitle }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionhero";
</style>
