<template>
    <div class="section section__ourservices">
        <ComponentAnchor :anchor="'our-services'"></ComponentAnchor>
        <div class="container">
            <div class="columns is-multiline">
                <div class="column">
                    <ComponentTitle
                        :tag="'h2'"
                        :theme="'primary-dark'"
                        :margin-top="'none'"
                        :margin-bottom="'large'"
                    >Our <strong>services</strong>
                    </ComponentTitle>
                    <SectionOurServicesMenuContent></SectionOurServicesMenuContent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentTitle from "@/components/shared/ComponentTitle";
import SectionOurServicesMenuContent from "@/sections/SectionOurServicesMenuContent";
import ComponentAnchor from "@/components/shared/ComponentAnchor";

export default {
  name: "SectionOurServices",
  components: { ComponentAnchor, SectionOurServicesMenuContent, ComponentTitle }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionourservices";
</style>
