<template>
    <header
        :class="[
            'header',
            scrollPosition > 0 ? 'header--modified' : ''
        ]">
        <div class="container">
            <div class="columns is-vcentered is-multiline">
                <div class="column has-text-centered-touch is-12-touch is-narrow-desktop">
                    <TheHeaderLogo></TheHeaderLogo>
                </div>
                <div class="column is-12-touch">
                    <TheHeaderNav></TheHeaderNav>
                </div>
                <div class="column has-text-centered-touch is-12-touch is-narrow-desktop">
                    <TheHeaderContact></TheHeaderContact>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import TheHeaderLogo from "./TheHeaderLogo";
import TheHeaderNav from "./TheHeaderNav";
import TheHeaderContact from "./TheHeaderContact";

export default {
  name: "TheHeader",
  components: { TheHeaderContact, TheHeaderNav, TheHeaderLogo },
  data () {
    return {
      scrollPosition: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.pageYOffset
    })
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/layout/header/header";
</style>
