<template>
    <div class="section__aboutus_image">
        <CircleLightBlueSvg :class="'section__aboutus_image_circle'"></CircleLightBlueSvg>
    </div>
</template>

<script>
import CircleLightBlueSvg from '@/assets/images/circle-light-blue.svg'

export default {
  name: "SectionAboutUsImage",
  components: {
    CircleLightBlueSvg
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionaboutusimage";
</style>