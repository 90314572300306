<template>
    <div class="component component__box">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "ComponentBox"
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentbox";
</style>
