<template>
    <div
        v-show="isOpened"
        class="component component__modal"
    >
        <div class="container">
            <div class="component__modal_top">
                <div class="columns is-vcentered">
                    <div class="column">
                        <ComponentParagraph :margin="'none'" :size="'large'" :weight="'heavy'">{{ title }}</ComponentParagraph>
                    </div>
                    <div class="column is-narrow">
                        <ComponentButton
                            :class="'component__modal_close'"
                            :theme="'secondary'"
                            :outline="true"
                            @click="closeModal"
                        >Close
                        </ComponentButton>
                    </div>
                </div>
            </div>
            <div class="component__modal_content">
                <div class="columns">
                    <div class="column">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentButton from "@/components/shared/ComponentButton";
import ComponentParagraph from "@/components/shared/ComponentParagraph";

export default {
  name: "ComponentModal",
  components: { ComponentParagraph, ComponentButton },
  data () {
    return {
      isOpened: false
    }
  },
  props: {
    title: {
      type: String
    }
  },
  methods: {
    openModal () {
      this.isOpened = true
      document.body.style.overflow = 'hidden'
    },
    closeModal () {
      this.isOpened = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentmodal";
</style>
