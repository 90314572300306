<template>
    <div class="section__aboutus_image_man">
        <Man></Man>
    </div>
</template>

<script>
import Man from '@/assets/images/man.svg'

export default {
  name: "SectionAboutUsImageMan",
  components: {
    Man
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/sections/sectionaboutusimageman";
</style>