<template>
    <div class="component component__textlist">
        <ul>
            <li v-for="(item, index) of items" :key="index" v-html="item"></li>
        </ul>
    </div>
</template>

<script>
export default {
  name: "ComponentTextList",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componenttextlist";
</style>
