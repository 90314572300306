<template>
    <div class="component component__anchor" :id="anchor"></div>
</template>

<script>
export default {
  name: "ComponentAnchor",
  props: {
    anchor: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentanchor";
</style>
