<template>
    <nav class="header__nav">
        <ul class="header__nav_list">
            <template v-for="(item, index) in navList">
                <li :key="index" class="header__nav_list_item">
                    <ComponentButton
                        :anchor="item.to"
                        :transparent="true"
                    >{{ item.label }}</ComponentButton>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script>
import ComponentButton from "@/components/shared/ComponentButton";
export default {
  name: "TheHeaderNav",
  components: { ComponentButton },
  computed: {
    navList () {
      let itemsArr = []
  
      itemsArr.push({
        label: 'About us',
        to: '#about-us'
      })
      itemsArr.push({
        label: 'Our services',
        to: '#our-services'
      })
      itemsArr.push({
        label: 'Case studies',
        to: '#case-studies'
      })
      
      return itemsArr
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/layout/header/headernav";
</style>
