<template>
    <div class="component component__paragraph">
        <p :class="classOutput">
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {
  name: "ComponentParagraph",
  props: {
    size: {
      type: String,
      default: 'normal',
      // allowed ['small', 'normal', 'medium', 'large']
    },
    align: {
      type: String,
      default: ''
      // allowed ['left', 'center', 'right']
    },
    weight: {
      type: String,
      default: 'normal'
      // allowed ['light', 'normal', 'bold', 'heavy']
    },
    margin: {
      type: String,
      default: 'normal'
      // allowed ['none', 'normal', 'big']
    },
    lineHeight: {
      type: String,
      default: 'medium'
      // allowed ['small', 'medium', 'big']
    },
    theme: {
      type: String,
      default: 'primary-dark'
      // allowed ['primary-dark', 'primary', 'white']
    }
  },
  computed: {
    classOutput () {
      return [
        `component__paragraph--text-size-${this.size}`,
        `component__paragraph--text-color-${this.theme}`,
        this.align ? `component__paragraph--text-align-${this.align}` : '',
        `component__paragraph--font-weight-${this.weight}`,
        `component__paragraph--margin-${this.margin}`,
        `component__paragraph--line-height-${this.lineHeight}`
      ]
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componentparagraph";
</style>
