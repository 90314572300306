<template>
    <div class="component component__title">
        <component
            :is="tag"
            :class="classOutput"
        >
            <slot></slot>
        </component>
    </div>
</template>

<script>
export default {
  name: "ComponentTitle",
  props: {
    tag: {
      type: String,
      default: 'h1'
    },
    theme: {
      type: String,
      default: 'primary-dark'
      // allowed ['primary-dark', 'primary', 'white']
    },
    marginBottom: {
      type: String,
      default: 'none'
      // allowed ['none', 'small', 'medium', 'large']
    },
    marginTop: {
      type: String
      // allowed ['none', 'small', 'medium', 'large', 'very-large']
    }
  },
  computed: {
    classOutput () {
      return [
        `component__title--text-color-${ this.theme }`,
        `component__title--margin-top-${ this.marginTop || this.marginBottom }`,
        `component__title--margin-bottom-${ this.marginBottom }`
      ]
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/components/componenttitle";
</style>
