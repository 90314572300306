<template>
    <div class="header__logo">
        <a href="#home">
            <Logo></Logo>
        </a>
    </div>
</template>

<script>
import Logo from '@/assets/images/logo.svg'

export default {
  name: "TheHeaderLogo",
  components: {
    Logo
  }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/layout/header/headerlogo";
</style>
